/** @jsx jsx */
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Box, jsx } from 'theme-ui'

import { Navigation } from 'swiper'
import VideoTestimonialItem from './Item'
import Heading from '~/components/IntlComponents/Heading'
import ArrowLeft from '~/assets/images/icons/arrow-left.svg'
import ArrowRight from '~/assets/images/icons/arrow-right.svg'

const VideoTestimonialsSection = ({
  page: { videoTestimonials, videoTestimonialTitle },
}) => {
  const slidelength = videoTestimonials.length
  return (
    <Box
      sx={{
        px: [0, '4rem', '8rem'],
        py: '6rem',
        mx: 'auto',
        background: '#FF2815',
        position: 'relative',
        '.arrow-left, .arrow-right': {
          display: ['none', 'none', 'block'],
          position: 'absolute',
          width: '3rem',
          height: '3rem',
          top: 'calc(50% + 3.725rem)',
          zIndex: 51,
          circle: {
            fill: 'pink',
            stroke: 'pink',
          },
        },
        '.arrow-left': {
          right: '8rem',
        },
        '.arrow-right': {
          left: '8rem',
        },
      }}
    >
      {/* using reverse arrows along with  reverse position ⬆️ to change the direction of action for the button  */}
      <ArrowLeft className="arrow-right" />
      <ArrowRight className="arrow-left" />
      <Heading
        sx={{
          textAlign: 'center',
          textTransform: 'uppercase',
          color: 'pink',
          fontSize: ['2rem', '3.725rem'],
          mb: ['2.5rem', null, '5rem'],
        }}
      >
        {videoTestimonialTitle}
      </Heading>
      <Box
        sx={{
          display: ['none', 'none', 'flex'],
          '.swiper ': {
            maxWidth: 'min(80%, 1500px)',
          },
          '.swiper-slide': {
            mx: '1.5rem',
          },
        }}
      >
        <Swiper
          modules={[Navigation]}
          slidesPerView={3.5}
          centeredSlides={true}
          spaceBetween={16}
          watchSlidesProgress={true}
          navigation={{ nextEl: '.arrow-left', prevEl: '.arrow-right' }}
          loop={true}
          initialSlide={Math.max(slidelength / 2, 1)}
          breakpoints={{
            1000: { slidesPerView: Math.min(3, slidelength) },
            1100: { slidesPerView: Math.min(3, slidelength) },
            1200: { slidesPerView: Math.min(3.5, slidelength) },
            1300: { slidesPerView: Math.min(4.0, slidelength) },
            1400: { slidesPerView: Math.min(4.5, slidelength) },
            1500: { slidesPerView: Math.min(5.0, slidelength) },
          }}
        >
          {videoTestimonials.map(testimonial => (
            <SwiperSlide key={testimonial.id}>
              {({ isActive, isVisible }) => (
                <VideoTestimonialItem
                  testimonial={testimonial}
                  isActive={isActive}
                  isVisible={isVisible}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box sx={{ variant: 'responsive.mobileTablet' }}>
        <Swiper
          slidesPerView={1.6}
          centeredSlides={true}
          spaceBetween={16}
          loop={true}
          watchSlidesProgress={true}
          breakpoints={{
            400: { slidesPerView: 1.8 },
            450: { slidesPerView: 2 },
            500: { slidesPerView: 2.2 },
            550: { slidesPerView: 2.4 },
            600: { slidesPerView: 2.6 },
            650: { slidesPerView: 2.8 },
            700: { slidesPerView: 3 },
          }}
        >
          {videoTestimonials.map(testimonial => (
            <SwiperSlide key={testimonial.id}>
              {({ isActive, isVisible }) => (
                <VideoTestimonialItem
                  testimonial={testimonial}
                  isActive={isActive}
                  isVisible={isVisible}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}

export default VideoTestimonialsSection
