/** @jsx jsx */
import { jsx, Box, Button } from 'theme-ui'
import React, { useRef, useState, useEffect } from 'react'
import PlayIcon from '~/assets/images/icons/video-player.svg'

const MaskedVideo = ({
  video,
  forwardSx = {},
  containerForwardSX = {},
  id,
  isMasked = true,
  squareVideo = false,
  customeClipPath = null, // pass a custom clippath if a custom shape for video is needed, will default to the star shape.
}) => {
  const [showPlayButton, setShowPlayButton] = useState(false)

  const clipPathID = `videoClipPath${id}`

  const videoRef = useRef()

  useEffect(() => {
    if (!videoRef.current) return

    let promise = videoRef.current.play()
    if (promise !== undefined) {
      promise
        .then(() => {
          setShowPlayButton(false)
        })
        .catch(e => {
          console.error('Error playing video', e)
          setShowPlayButton(true)
        })
    }
  }, [videoRef])

  function togglePlaying() {
    if (!videoRef.current) return
    videoRef.current.play()
  }
  if (!video) return null

  return (
    <div
      sx={{
        clipPath: isMasked && `url(#${clipPathID})`,
        position: 'relative',
        paddingTop: isMasked && 'calc(100% / 2.3)',
        width: '100%',
        height: !isMasked && '100%',
        aspectRatio: squareVideo && '1',
        ...containerForwardSX,
      }}
    >
      {showPlayButton && (
        <Button
          variant="bare"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            color: 'schoolBusYellow',
            path: {
              fill: 'goodlesRed',
            },
          }}
          onClick={togglePlaying}
        >
          <PlayIcon />
        </Button>
      )}
      <video
        ref={videoRef}
        sx={{
          transform: isMasked && !squareVideo && `scale(1.3)`,
          top: 0,
          left: 0,
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          ...forwardSx,
        }}
        src={`https:${video?.file.url}#t=0.1`}
        autoPlay
        playsInline
        loop
        muted
        controls={false}
      />
      <svg width="0" height="0">
        <defs>
          <clipPath id={clipPathID} clipPathUnits="objectBoundingBox">
            {customeClipPath ?? (
              <path d="M0.461,0.025 C0.478,-0.007,0.524,-0.007,0.54,0.025 L0.61,0.161 C0.62,0.18,0.643,0.19,0.663,0.183 L0.809,0.136 C0.844,0.125,0.876,0.158,0.865,0.192 L0.819,0.338 C0.812,0.359,0.821,0.381,0.84,0.391 L0.977,0.461 C1,0.478,1,0.524,0.977,0.54 L0.84,0.61 C0.821,0.62,0.812,0.643,0.819,0.663 L0.865,0.809 C0.876,0.844,0.844,0.876,0.809,0.865 L0.663,0.819 C0.643,0.812,0.62,0.821,0.61,0.84 L0.54,0.977 C0.524,1,0.478,1,0.461,0.977 L0.391,0.84 C0.381,0.821,0.359,0.812,0.338,0.819 L0.192,0.865 C0.158,0.876,0.125,0.844,0.136,0.809 L0.183,0.663 C0.19,0.643,0.18,0.62,0.161,0.61 L0.025,0.54 C-0.007,0.524,-0.007,0.478,0.025,0.461 L0.161,0.391 C0.18,0.381,0.19,0.359,0.183,0.338 L0.136,0.192 C0.125,0.158,0.158,0.125,0.192,0.136 L0.338,0.183 C0.359,0.19,0.381,0.18,0.391,0.161 L0.461,0.025"></path>
            )}
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default MaskedVideo
