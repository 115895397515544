/** @jsx jsx */
import { jsx, useThemeUI, Container, Box } from 'theme-ui'
import React, { useLayoutEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import ImgLoader from '../Generic/ImgLoader'
import ImageAndTextSection from '~/components/Product/Page/ImageAndTextSection'
import { WigglySectionDivider } from '~/components/Generic/SectionDivider/WigglySectionDivider'
import { MobileWigglySectionDivider } from '~/components/Generic/SectionDivider/MobileWigglySectionDivider'
import { renderImageOrVideo } from '~/utils/render-image-or-video'
import useWindowSize from '~/hooks/components/use-window-size'
import ArrowLeft from '~/assets/images/icons/arrow-left.svg'
import ArrowRight from '~/assets/images/icons/arrow-right.svg'
import 'swiper/css/pagination'
import { lineHeights } from '~/theme'

const HeroSection = ({
  page: {
    hero,
    heading,
    subheading,
    heroImage,
    heroVideoFallback,
    localButtonStyle,
    mobileHeroVideo,
    heroCallToAction,
  },
  isMobile,
  activeHero,
  setActiveHero,
}) => {
  const { theme } = useThemeUI()

  const noodleSectionBackgroundColor =
    activeHero?.noodleBowlSection?.backgroundColor
  const mobileVideo = !!mobileHeroVideo ? mobileHeroVideo : heroVideoFallback

  return (
    <Container
      p={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: ['center', 'flex-start'],
        width: '100%',
        paddingX: '0 !important',
        position: 'relative',
        height: ['64vh', '92vh', '95vh'],
        backgroundColor: 'primary',
      }}
    >
      <Box
        sx={{
          height: '100%',
          '.swiper ': {
            height: '100%',
          },
          '.swiper-pagination': {
            mb: '2rem',
          },
          '.swiper-pagination-bullet-active': {
            backgroundColor: 'japaneseLaurel',
          },
          '.arrow-left-hero, .arrow-right-hero': {
            display: ['none', 'none', 'block'],
            position: 'absolute',
            width: '3rem',
            height: '3rem',
            top: '70%',
            zIndex: 51,
            circle: {
              fill: 'secondary',
              stroke: 'secondary',
            },
            path: {
              fill: 'primary',
            },
          },
          '.arrow-left-hero': {
            right: '2rem',
          },
          '.arrow-right-hero': {
            left: '2rem',
          },
        }}
      >
        {/* using reverse arrows along with  reverse position ⬆️ to change the direction of action for the button  */}
        <ArrowLeft className="arrow-right-hero" />
        <ArrowRight className="arrow-left-hero" />
        <Swiper
          onSlideChange={swiper => setActiveHero(hero[swiper.realIndex])}
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          centeredSlides
          initialSlide={0}
          loop={true}
          loopedSlides={3}
          pagination={!!isMobile}
          navigation={{
            nextEl: '.arrow-left-hero',
            prevEl: '.arrow-right-hero',
          }}
        >
          {hero?.map(h => (
            <SwiperSlide key={h.id}>
              {/* Desktop video */}
              <>
                <ImageAndTextSection
                  cta={h.heroCallToAction}
                  gatsbyImageData={heroImage.gatsbyImageData}
                  headingText={h?.heading?.heading}
                  subHeadingText={
                    h?.largeSubheading?.largeSubheading ||
                    h?.subheading?.subheading
                  }
                  containerStyle={{
                    display: ['none', 'flex'],
                    position: 'absolute',
                    mt: ['125px', '110px'],
                    color: 'secondary',
                    paddingBottom: [0, 0, 120],
                    maxWidth: 1440,
                    width: ['auto', (626 + 50) * 2],
                    zIndex: 20,
                  }}
                  headingForwardSx={{
                    fontSize: '5rem !important',
                    maxWidth: '13ch',
                    lineHeight: '4.5rem !important',
                  }}
                  subHeadingForwardSx={{
                    fontSize: h?.largeSubheading?.largeSubheading
                      ? ['2rem']
                      : ['1rem', '1rem', '1rem'],
                    fontWeight: 800,
                    my: [0, '1rem'],
                  }}
                  buttonStyle={{
                    width: '100%',
                    maxWidth: '320px',
                    color:
                      h.heroCallToAction.textColor ||
                      'override.press.button.color',
                    backgroundColor:
                      h.heroCallToAction.buttonColor ||
                      'override.press.button.back',
                    ':hover': {
                      color:
                        h.heroCallToAction.buttonColor ||
                        'override.press.button.hover.color',
                      backgroundColor:
                        h.heroCallToAction.textColor ||
                        'override.press.button.hover.back',
                    },
                  }}
                />
                <div
                  sx={{
                    position: ['relative', 'absolute'],
                    width: '100%',
                    height: '120%',
                    left: 0,
                    bottom: [0, '-150px'],
                  }}
                >
                  {renderImageOrVideo(isMobile ? h.mobileHeroVideo : h.image, {
                    VideoComponent: video => (
                      <video
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          objectPosition: '50%',
                        }}
                        src={`https:${video.file.url}`}
                        autoPlay
                        playsInline
                        loop
                        muted
                        controls={false}
                      />
                    ),
                    ImageComponent: image => (
                      <ImgLoader
                        forwardSx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          objectPosition: '90%',
                        }}
                        image={image}
                      ></ImgLoader>
                    ),
                  })}
                </div>
              </>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      {/* This one subverts the pattern of having these dividers at the top level because of the transparency & video overlap */}
      {/* Would be nice to refactor this into one component but right now I'm not entirely sure how that fits with all designs */}
      <div
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          zIndex: 10,
          transform: 'translate3d(0,0,100px)',
        }}
      >
        <WigglySectionDivider
          forwardSx={{
            display: ['none', 'flex'],
          }}
          small
          topColor="transparent"
          bottomColor={noodleSectionBackgroundColor || theme.colors.frenchPass}
        />
      </div>
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'absolute',
          bottom: '-4px',
          zIndex: 10,
        }}
        topColor="transparent"
        medium
        bottomColor={noodleSectionBackgroundColor || theme.colors.frenchPass}
      />
    </Container>
  )
}

export default HeroSection
