/* @jsx jsx */
import { Button, jsx } from 'theme-ui'
import PropTypes from 'prop-types'

const GUTTER = '6.875%'

export function ArrowButton({ invert, onClick, isMobile, forwardSx }) {
  const dimension = isMobile ? 37 : 52
  return (
    <Button
      variant="link"
      onClick={onClick}
      sx={{
        padding: 0,
        margin: 0,
        position: 'absolute',
        left: invert ? GUTTER : 'unset',
        right: invert ? 'unset' : GUTTER,
        top: '50%',
        zIndex: 4,
        transform: 'translateY(-50%)',
        ...forwardSx
      }}
    >
      <svg
        width={dimension}
        height={dimension}
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        sx={{
          transform: invert ? 'scale(-1, 1)' : 'scale(1, 1)',
          ':hover, :focus, :active': {
            cursor: 'pointer',
            circle: {
              fill: '#FF5A47'
            },
            path: {
              fill: '#FFDD00'
            },
            '*': {
              transition: 'fill 0.2s ease'
            }
          }
        }}
      >
        <circle
          cx="26"
          cy="26"
          r="25"
          fill="#FFDD00"
          stroke="#FF5A47"
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.1129 10.1913L41.0627 25.1858L41.8155 26.0575L41.0627 26.9292L28.1129 41.9237L26.0942 40.1803L37.1396 27.3909H9.83862V24.7236H37.1392L26.0942 11.9347L28.1129 10.1913Z"
          fill="#FF5A47"
        />
      </svg>
    </Button>
  )
}

ArrowButton.propTypes = {
  invert: PropTypes.bool,
  onClick: PropTypes.func,
  isMobile: PropTypes.bool
}
