/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import StyledWrapper from '../Generic/StyledWrapper'
import TextSectionWithButton from '../Generic/TextSectionWithButton'
import MaskedVideo from '../Generic/MaskedVideo'
import useMatchMedia from '~/hooks/utils/use-match-media'

const GeneralHighlightSection = ({
  page: { generalHighlight = {} },
  isMobile,
}) => {
  const {
    flipped,
    heading,
    image,
    subheading,
    desktopVideo,
    mobileVideo,
    callToAction,
  } = generalHighlight
  const childrenBeforeButton = useMatchMedia('(max-width: 768px)')

  return (
    <TextSectionWithButton
      cta={callToAction}
      flipped={flipped}
      headingText={heading}
      subheadingText={subheading}
      childrenBeforeButton={childrenBeforeButton}
      containerStyle={{
        backgroundColor: 'schoolBusYellow',
        color: 'japaneseLaurel',
        width: '100%',
        px: ['2rem', 0],
        gridTemplateColumns: [
          '1fr',
          'repeat(2, minmax(min-content, 32.25rem))',
        ],
        justifyContent: ['center', 'center'],
        alignItems: 'center',
        gap: '2.5rem',
        '& h2': {
          fontSize: ['2.75rem', '6.25rem'],
          lineHeight: 0.8,
        },
        '& p': {
          fontSize: ['1.125rem', '1.25rem'],
          maxWidth: '27rem',
          lineHeight: 1.2,
        },
      }}
      buttonStyle={{
        backgroundColor: 'governorBay',
        color: 'schoolBusYellow',
        ':hover': {
          backgroundColor: 'japaneseLaurel',
        },
        mx: ['auto', 'inherit'],
        mt: '2rem',
      }}
    >
      {image ? (
        <StyledWrapper>
          <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
        </StyledWrapper>
      ) : (
        <>
          {!isMobile ? (
            <StyledWrapper
              forwardSx={{
                display: ['none', 'block'],
                aspectRatio: '16/9',
              }}
            >
              <MaskedVideo
                video={desktopVideo ?? mobileVideo}
                id="1"
                isMasked={false}
                forwardSx={{ borderRadius: '15px' }}
              />
            </StyledWrapper>
          ) : (
            <StyledWrapper
              forwardSx={{
                display: ['block', 'none'],
                aspectRatio: '16/9',
              }}
            >
              <MaskedVideo
                video={mobileVideo ?? desktopVideo}
                id="2"
                isMasked={false}
                forwardSx={{ borderRadius: '15px' }}
              />
            </StyledWrapper>
          )}
        </>
      )}
    </TextSectionWithButton>
  )
}

export default GeneralHighlightSection
