/** @jsx jsx */
import React from 'react'
import { Container, Box, jsx } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import StyledWrapper from '../Generic/StyledWrapper'
import TextSectionWithButton from '../Generic/TextSectionWithButton'

const ProductHighlightSection = ({ page: { productHighlightList = [] } }) => {
  return (
    <Container as="section" sx={{ backgroundColor: 'governorBay', p: 0 }}>
      {productHighlightList.map(
        ({
          id,
          flipped,
          heading,
          image,
          statementHeading,
          subheading,
          callToAction,
          mobileVideo,
          desktopVideo,
        }) => (
          <TextSectionWithButton
            key={id}
            cta={callToAction}
            headingText={heading}
            flipped={flipped}
            statementHeadingText={statementHeading}
            subheadingText={subheading}
            containerStyle={{
              mx: 'auto',
              color: 'schoolBusYellow',
              gap: ['2rem', '9rem'],
              gridTemplateColumns: [
                '1fr',
                'minmax(min-content, 33rem) minmax(min-content, 27rem)',
              ],
              justifyContent: ['center', 'center'],
            }}
            buttonStyle={{
              width: '100%',
              backgroundColor: 'sunsetOrange',
              color: 'inherit',
              '& > span': {
                maxWidth: ['13.375rem', '100%'],
                lineHeight: 1.2,
                whiteSpace: 'normal',
              },
            }}
          >
            <StyledWrapper>
              {/* Why are we checking for this if we're using the mobile video only? */}
              {!!desktopVideo || !!mobileVideo ? (
                <Box
                  sx={{
                    borderRadius: '50%',
                    overflow: 'hidden',
                    maxHeight: ['26rem', null, '36rem'],
                    isolation: 'isolate',
                    m: 0,
                    mx: 'auto',
                    aspectRatio: '1/1',
                    '@supports not(aspect-ratio: 1/1)': {
                      height: 0,
                      paddingTop: '100%',
                      position: 'relative',
                    },
                  }}
                >
                  <video
                    src={desktopVideo?.file?.url ?? mobileVideo?.file?.url}
                    autoPlay
                    playsInline
                    loop
                    muted
                    controls={false}
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      '@supports not(aspect-ratio: 1/1)': {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                      },
                    }}
                  />
                </Box>
              ) : (
                !!image && (
                  <Box
                    sx={{
                      borderRadius: '50%',
                      overflow: 'hidden',
                      maxHeight: ['26rem', null, '36rem'],
                      aspectRatio: '1',
                    }}
                  >
                    <GatsbyImage
                      image={image.gatsbyImageData}
                      alt={image.title}
                    />
                  </Box>
                )
              )}
            </StyledWrapper>
          </TextSectionWithButton>
        ),
      )}
    </Container>
  )
}

export default ProductHighlightSection
