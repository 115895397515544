/** @jsx jsx */
import React from 'react'
import { jsx, Flex, useThemeUI, Button, Box } from 'theme-ui'

import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import ArticleCard from '../Article/Card'
import { LinkWrapper } from '../Product/Page/LinkWrapper'
import Text from '../IntlComponents/Text'
import Heading from '~/components/IntlComponents/Heading'
import StyledWrapper from '~/components/Generic/StyledWrapper'
import Stamp from '~/assets/images/icons/stampGooder.svg'

const ArticleHighlightSection = ({
  page: { articleHighlightList },
  homePageButtonStyle,
}) => {
  const { theme } = useThemeUI()
  const translate = useTranslate()

  return (
    <>
      <Flex
        sx={{
          marginTop: ['0em', '0'],
          marginBottom: ['3em', '0'],
          marginLeft: ['auto', 'unset'],
          marginRight: ['auto', 60],
          justifyContent: ['center', 'flex-end'],
        }}
      >
        <Stamp sx={{ '& path': { fill: theme.colors.schoolBusYellow } }} />
      </Flex>
      <StyledWrapper>
        <Heading
          variant="text.h4"
          sx={{
            textAlign: 'center',
            marginBottom: 60,
            color: theme.colors.schoolBusYellow,
          }}
        >
          {translate('blog_page.gooder_times_are_here')}
        </Heading>
      </StyledWrapper>
      <Box
        className={'hideScroll'}
        sx={{ width: '100vw', height: '100%', overflowX: ['scroll', 'unset'] }}
      >
        <Flex
          sx={{
            padding: ['0', 'unset'],
            position: 'relative',
            gap: ['30px', '60px'],
            m: ['0 10px', '0 auto'],
            justifyContent: ['space-around', 'center'],
            width: ['250%', 'unset', 'unset'],

            '& .card-container': {},
            '& > a': {
              flexBasis: 0,
              flexGrow: 1,
              maxWidth: ['375px', '315px'],
              color: '#2B38BD ',
              borderColor: '#2B38BD ',
            },
          }}
        >
          {articleHighlightList.map((x, i) => {
            return (
              <ArticleCard
                key={'desktop-' + x.id}
                article={x}
                ignoreFeatured={true}
              />
            )
          })}
        </Flex>
      </Box>
      <Flex sx={{ justifyContent: 'center' }}>
        <LinkWrapper
          sx={{
            pt: 30,
            width: 'max-content',
          }}
          slug={'/blog'}
          contenttype={'ContentfulPage'}
        >
          {/* -8 here is the final compensation for the height of the nav bar */}
          {/* TODO: Make this translated based on locale */}
          <Button sx={{ mb: '2rem', ...homePageButtonStyle }}>
            {translate('blog_page.title')}
          </Button>
        </LinkWrapper>
      </Flex>
    </>
  )
}

export default ArticleHighlightSection
