/** @jsx jsx */
import { alpha } from '@theme-ui/color'
import React, { forwardRef } from 'react'
import { Box, Flex, Progress, jsx } from 'theme-ui'

const ProgressBar = forwardRef(({ currentTime, setCurrentTime }, ref) => {
  const handleProgressBarClick = ({ target, clientX }) => {
    const { width, left } = target.getBoundingClientRect()
    // calculate time to skip depending on progress bar width and click position
    const time = (ref.current.duration * (clientX - left)) / width
    // update the video time
    ref.current.currentTime = time
    // update progress bar
    setCurrentTime(time)
  }

  return (
    <Flex
      sx={{
        position: 'absolute',
        zIndex: 10,
        top: 0,
        left: 0,
        right: 0,
        p: '1.25rem',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <Box
        sx={t => ({
          width: '100%',
          position: 'relative',
          backgroundColor: alpha(t.rawColors.sunsetOrange, 0.2),
          borderRadius: '1rem',
          cursor: 'pointer'
        })}
        onClick={handleProgressBarClick}
      >
        <Box
          sx={{
            height: '.25rem',
            borderRadius: '1rem',
            width: '100%',
            '&::before': {
              content: '""',
              position: 'absolute',
              inset: 0,
              borderRadius: '1rem',
              height: '.25rem',
              minWidth: '.25rem',
              width: `${(currentTime / ref.current?.duration) * 100}%`,
              backgroundColor: 'sunsetOrange'
            }
          }}
        >
          <Progress
            value={currentTime}
            max={isNaN(ref.current?.duration) ? 0 : ref.current?.duration}
            sx={{
              display: 'block',
              backgroundColor: 'transparent',
              color: 'transparent',
              appearance: 'none',
              height: '.25rem',
              width: '100%',
              zIndex: 2
            }}
          />
        </Box>
      </Box>
    </Flex>
  )
})

export default ProgressBar
