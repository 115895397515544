/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { Grid, jsx } from 'theme-ui'

import { keyframes } from '@emotion/react'
import Text from '~/components/IntlComponents/Text'

const floatAnimObj = (distance, duration) => {
  const float = keyframes`
      0% { transform: translate(0,  0px); }
      50%  { transform: translate(0, -${distance}px); }
      100%   { transform: translate(0, -0px); }
      `
  return {
    animation: `${float} ${duration}ms infinite ease-in-out`,
  }
}

function BouncyIngredientIcon({
  justifySelf,
  imgObj,
  imgStyle,
  forwardSx,
  distance = 15,
}) {
  if (!imgObj) return null
  return (
    <div
      sx={{
        width: 'fit-content',
        justifySelf: ['center', justifySelf],
      }}
    >
      <Grid
        sx={{
          margin: 'auto',
          ...floatAnimObj(distance, 6000 + Math.random() * 1000),
          ...forwardSx,
        }}
      >
        <GatsbyImage
          imgStyle={{ objectFit: 'contain', ...imgStyle }}
          image={imgObj.gatsbyImageData}
          alt={imgObj.title}
        />
        <Text variant="text.eyebrow">{imgObj.title}</Text>
      </Grid>
    </div>
  )
}

export default BouncyIngredientIcon
