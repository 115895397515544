/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { Grid, jsx } from 'theme-ui'
import MaskedVideo from '../Generic/MaskedVideo'
import StyledWrapper from '~/components/Generic/StyledWrapper'
import TextSectionWithButton from '~/components/Generic/TextSectionWithButton'

const PageHighlightsSection = ({
  page: { pageHighlightList },
  homePageButtonStyle,
}) => {
  return (
    <div sx={{ color: 'sunsetOrange', backgroundColor: 'pink', py: [10, 90] }}>
      <Grid sx={{ justifyContent: 'center' }}>
        {pageHighlightList.map((highlight, i) => {
          const { desktopVideo, mobileVideo } = highlight
          return (
            <TextSectionWithButton
              headingText={highlight.heading}
              subheadingText={highlight.subheading}
              cta={highlight.callToAction}
              buttonStyle={{
                mx: ['auto', 'inherit'],
                ...homePageButtonStyle,
              }}
              flipped={highlight.flipped}
              containerStyle={{ gridGap: 60 }}
              key={`${highlight.heading}_${i}}`}
            >
              <StyledWrapper
                forwardSx={{
                  display: mobileVideo ?? desktopVideo ? 'flex' : 'block',
                  justifyContent:
                    mobileVideo ?? desktopVideo
                      ? ['center', 'center', 'flex-end']
                      : 'unset',
                }}
              >
                {desktopVideo || mobileVideo ? (
                  <MaskedVideo
                    video={mobileVideo ?? desktopVideo}
                    id={i}
                    forwardSx={{ borderRadius: '15px' }}
                    containerForwardSX={{
                      maxWidth: ['70vw', '70vw', 'min(45vw, 600px)'],
                    }}
                    squareVideo
                    isMasked={false}
                  />
                ) : (
                  <GatsbyImage
                    image={highlight.image.gatsbyImageData}
                    alt={highlight.image.title}
                  />
                )}
              </StyledWrapper>
            </TextSectionWithButton>
          )
        })}
      </Grid>
    </div>
  )
}

export default PageHighlightsSection
